.rankingContainer{
    /*width: 88%;
    margin-left: 6%;*/
    margin-top: 16%;
}
.logoRanking {
    height: 40px;
    margin-top: 10px;
    /* margin-left: 20px; */
    margin-bottom: 20px;
    width: 100%;
    object-fit: contain;
}
.rankingLogoContainer{
    text-align: left;
}
.logoContainer span{
    text-transform: uppercase;
    color: #FFFFFF;
    font-size: 1.5em;
    font-weight: 800;
    font-family: Poppins, sans-serif;
    vertical-align: text-bottom;
    margin-left: 20px;
}
.rankStatusContainer{
    text-align: left;
    margin-left: 20px;
    margin-top: 20px;
}
.rankStatusCircle{
    border-radius: 50%;
    height: 64px;
    width: 64px;
    display: inline-block;
    background-color: #EDAA00;
    text-align: center;
    vertical-align: top;
}
.intermediate{
    background-color: #163D34;
}
.expert{
    background-color: #8b0000;
}
.iconRankStatus{
    margin-top: 25%;
    height: 50%;
}
.rankStatusInfoContainer{
    display: inline-block;
    margin-left: 20px;
    width: 66%;
}
.rankStatusInfoContainer span{
    color: #FFFFFF;
    font-family: Poppins, sans-serif;
    font-weight: 600;
    font-size: 1em;

}
.rankStatusInfoShares{
    float: right;
}
.rankStatusContainer .missingShares{
    font-size: 0.6em;
}
.iconStepper{
    height: 50%;
    margin-top: 25%;
    max-width: 50%;
}
.stepperTitle{
    font-family: Poppins, sans-serif;
    font-weight: 600!important;
}
.prizesContainer{
    margin-top: 40px;
    text-align: left;
}
.prizesTitle{
    color: #FFFFFF;
    font-size: 1.2em;
    font-weight: 800;
    font-family: Poppins, sans-serif;
    text-align: left;
    margin-left: 20px;
    text-transform: uppercase;
}
.msPrizes{
    display: block;
}
.circlePrizes{
    height: 52px;
    width: 52px;
}
.rankingPreP{
    text-transform: uppercase;
    color: #FFFFFF;
    font-size: 1.5em;
    font-weight: 800;
    font-family: Poppins, sans-serif;
    vertical-align: text-bottom;
    margin-left: 20px;
    margin-right: 20px;
    text-align: center;
}
@media (min-width: 767px) {
    .rankingContainer{
        width: 60%;
        max-width: 1000px;
        margin: auto;
        margin-top: 8%;
    }
    .rankingPreP{
        font-size: 1.7em
    }
    .logoContainer span{
        font-size: 1.7em
    }
    .rankStatusInfoContainer span{
        font-size: 1.2em;
    }
    .rankStatusContainer .missingShares{
        font-size: 1em;
    }
    .prizesTitle{
        font-size: 1.5em;
    }
    .rankStatusInfoContainer{
        width: 80%;
    }
    .rankStatusContainer{
        margin-top: 40px;
        margin-bottom: 40px;
    }
    .logoRanking {
        height: 70px;
    }
}